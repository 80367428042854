import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { signOut } from '../../redux/features/Auth.services';
import { useAppDispatch } from '../../hooks/hooks';
import appRoutes from '../../routes/appRoutes';
import SideBarItem from './SideBarItem';
import './styles.scss';

const SideBarMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ photoName, setPhotoName] = useState<string>();
  const [ srcImage, setSrcImage] = useState<string>();
  const { userconfig, pageState } = useSelector((state: RootState) => state.appState);
  
  const logOut = () => {
    window.localStorage.removeItem('userconfig');
    dispatch(signOut()).then(() => navigate('/', { replace: true }))
  }

  const renderPhoneProfile = (dataProfile: any) => {
    setPhotoName(dataProfile.foto_perfil_nombre!);

    if (dataProfile.foto_perfil_tipo === 'png')
      setSrcImage('data:image/png;base64,' + dataProfile.foto_perfil_data);
    else if (dataProfile.foto_perfil_tipo === 'jpg')
      setSrcImage('data:image/jpeg;base64,' + dataProfile.foto_perfil_data);
  }

  useEffect(() => {
    if (userconfig !== null) {
      if (userconfig[0].foto_perfil_data !== null) {
        renderPhoneProfile(userconfig[0]);
      }
    } else {
      var dataUser = JSON.parse(window.localStorage.getItem('userconfig')!);
      
      if (dataUser !== undefined && dataUser !== null) {
        renderPhoneProfile(dataUser[0]);
      }
    }
    
  }, [userconfig]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="min-vh-100 d-flex justify-content-between flex-column pe-0 ps-0 content-sidebar">
          <div>
            <div className="site-title mt-4 ps-2">
              <h4 className="text-uppercase font-face-ws-bold fs-6 text-center">Juancho te descuenta</h4>
            </div>
            <div className="container mt-5 mb-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-12" style={{ 
                  backgroundColor: 'rgba(196, 196, 196, 0.16)',
                  borderRadius: '50%',
                  width: '120px',
                  height: '120px',
                  textAlign: 'center'
                }}>
                  {
                    srcImage ? <img src={`${srcImage}`} style={{ width: '100%', paddingTop: '1rem' }} alt={`${photoName}`} /> : null
                  }
                  </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <p className="text-uppercase text-center font-face-ws-sBold">{userconfig ? userconfig[0].nombreconvenio : ""}</p>
                </div>
              </div>
            </div>
            <ul className={`nav nav-pills flex-column nav-left-side ${pageState === "detalles" ? "nav-left-sel-element": ""}`}>
              {
                appRoutes.filter(x => x.showInSideBar === true).map((route, index) => (
                  route.sidebarProps ? (
                    <SideBarItem item={ route } key={ index }/>
                  ) : null
                ))
              }
            </ul>
          </div>
          <div className="btn-exit">
            <div className="pb-4">
              <div className="container-btn">
                <button type="button"
                  className="btn btn-link w-100 text-start text-uppercase nav-link fs-5 py-1 px-3"
                  onClick={ logOut }
                >
                  salir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBarMenu