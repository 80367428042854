import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { AgGridReact } from 'ag-grid-react';
import { DataNovedadAprobados } from '../interfaces/novedadesInterface';
import { ColDef } from 'ag-grid-community';
import { useAprobados } from '../hooks/useAprobados';
import { getNumberWithDecimal } from '../utils/helper';

type Props = {}

const TabAprobados = (props: Props) => {
    const gridRef = useRef<AgGridReact>(null);
    const [ rowData, setRowData ] = useState<DataNovedadAprobados[]>([]);
    const { aprobados } = useAprobados();

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const formatValueCurrency = (params: any) => {
        if (params.value !== undefined && params.value !== '' && params.value !== null) {
          return getNumberWithDecimal(params.value);
        } else {
          return '-'
        }
    }

    const [columnDefs] = useState([
        {
            headerName: "Nombre",
            field: "nombrecompleto",
            minWidth: 225,
            cellClass: "details-cell",
        },
        {
            headerName: "Cédula",
            field: "cedula",
            minWidth: 130,
            cellClass: "details-cell",
        },
        {
            headerName: "NIT",
            field: "nit",
            minWidth: 145,
            cellClass: "details-cell",
        },
        {
            headerName: "Cuota autorizada",
            field: "cuotaautorizada",
            minWidth: 165,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Plazo",
            field: "plazo",
            minWidth: 120,
            cellClass: "details-cell",
        },
        {
            headerName: "Fecha aprobación",
            field: "fechaaprobacion",
            minWidth: 165,
            cellClass: "details-cell",
        },
        {
            headerName: "Monto solicitado",
            field: "montosolicitado",
            minWidth: 170,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Saldo restante",
            field: "saldorestante",
            minWidth: 160,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        }
    ]);

    const onGridReady = useCallback((params: any) => {
        if (aprobados && aprobados.data.length > 0) {
            setRowData(aprobados.data)
        }
    }, []);

    useEffect(() => {
        if (aprobados && aprobados.data.length > 0) {
            setRowData(aprobados.data)
        } else {
            setRowData([]);
        }
    }, [ aprobados ])


    return (
        <Card body className="rounded-0 border-0" style={{ height: "calc(95vh - 300px)" }}>
            <Stack className="h-100 ag-theme-material">
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    rowHeight={50}
                    pagination={true}
                    onGridReady={onGridReady}
                ></AgGridReact>
            </Stack>
        </Card>
    )
}

export default TabAprobados