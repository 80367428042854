import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMutation, useQueryClient } from 'react-query';
import { jtdApi } from '../../api/JTDApi';
import { useAppDispatch } from '../../hooks/hooks';
import UseNotification from '../../hooks/UseNotification';
import { TypeNotification } from '../../interfaces/messageNotificationInterface';
import { DataNovedad, NovedadDecision } from '../../interfaces/novedadesInterface';
import { setShowLoadingState } from '../../redux/features/AppState.slice';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: DataNovedad
}

const AprobarModal = (props: Props) => {
    const { showNotification } = UseNotification();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onApproveNovedad = async () => {
        showLoading(true);
        if (props.data) {
            const { id, cedula, contrato } = props.data;
            const payload = {
                novedadid: id,
                decisions: [
                    {
                      "decision": NovedadDecision.Approved,
                      "installmentValue": 0,
                      "identification": cedula,
                      "loanId": +contrato
                    }
                ]
            }

            await jtdApi.post(`/api/aprobarnovedad`, payload);
        } else {
            runNotification('Algo salio mal, vuelva a intentarlo');
            showLoading(false)
        }
    }

    const {mutate : approveNovedad} = useMutation(onApproveNovedad, {
        onSuccess: () => {
            queryClient.invalidateQueries('getNovedades');
            queryClient.invalidateQueries('getAprobados');
            showLoading(false);
            props.onHide();
        },
        onError: (err: any) => {
            runNotification(err.message ? err.message : 'Algo salio mal, vuelva a intentarlo');
            showLoading(false);
        }
    });

    const runNotification = (msg: string, typeNot: TypeNotification = TypeNotification.warning) => {
        showNotification({
            show: true,
            message: msg,
            typeNotification: typeNot
        });
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="approve-modal"
        >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>
                <div className="container">
                    <p className="text-center">¿Apruebas el descuento por nómina?</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <div className="mx-auto approve-btn-actions btn-submit">
                    <Button className="btn me-2 text-uppercase" onClick={() => approveNovedad()}>Si</Button>
                    <Button className="btn text-uppercase" onClick={props.onHide}>Cancelar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AprobarModal