import { useEffect, useState } from 'react';
import { jtdApi } from '../api/JTDApi';
import { INovedadCollection } from '../interfaces/novedadesInterface';

type Props = {
    modulo: string
}

const useMotivosList = (props: Props) => {
    const [ motivoList, setMotivoList ] = useState<INovedadCollection>();
    
    const getList = async() => {
        try {
            await jtdApi.get<INovedadCollection>(`/api/motivoslist`, {
                params: {
                    modulo: props.modulo
                }
            }).then((response) => {
                setMotivoList(response.data);
            });
        } catch (error) {}
    }

    useEffect(() => {
        getList();
    }, [])
    
    return {
        motivoList
    }
}

export default useMotivosList