import { Auth } from '@aws-amplify/auth';

const awsconfig = {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: true,
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
}

Auth.configure(awsconfig);
