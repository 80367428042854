import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';
import { ColDef, IRowNode } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import BtnCellLiquidationRender from '../components/BtnCellLiquidationRender';
import PagarModal from '../components/widgets/PagarModal';
import { useLiquidacion } from '../hooks/useLiquidacion';
import { ILiquidacionExcelData, Liquidaciones } from '../interfaces/liquidacionInterface';
import { RootState } from '../redux/store';
import { jtdApi } from '../api/JTDApi';
import { getNumberWithDecimal } from '../utils/helper';
import { useAppDispatch } from '../hooks/hooks';
import { setShowLoadingState } from '../redux/features/AppState.slice';

type Props = {}

const Liquidacion = (props: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const [ modalPayShow, setModalPayShow ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ showPayAll, setShowPayAll ] = useState<boolean>(false);
  const [ rowData, setRowData ] = useState<Liquidaciones[]>([]);
  const { liquidaciones } = useLiquidacion();
  const { userconfig } = useSelector((state: RootState) => state.appState);
  const [ montoTotal, setMontoTotal ] = useState<string>('-');
  const [ fechaPago, setFechaPago ] = useState<string>('-');
  const [ currentLiquidaciones, setCurrentLiquidaciones ] = useState<Liquidaciones[]>([]);
  const [ countList, setCountList ] = useState(0);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "Nombre",
      field: "nombre",
      minWidth: 215,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellClass: "details-cell",
    },
    {
      headerName: "NIT",
      field: "NIT",
      minWidth: 135,
      cellClass: "details-cell",
    },
    {
      headerName: "Frecuencia",
      field: "frecuencia",
      minWidth: 165,
      cellClass: "details-cell",
    },
    {
      headerName: "Periodo de liquidación",
      field: "periodo_pago",
      minWidth: 170,
      cellClass: "details-cell",
    },
    {
      headerName: "Valor a pagar",
      field: "valor_total_a_pagar",
      minWidth: 190,
      valueFormatter: (params: any) => {
        if (params.value !== undefined && params.value !== '' && params.value !== null) {
          return getNumberWithDecimal(params.value);
        } else {
          return '-'
        }
      },
      cellClass: "details-cell",
    },
    {
      headerName: "Fecha limite de pago",
      field: "fecha_limite_pago",
      minWidth: 175,
      cellClass: "details-cell",
    },
    {
      headerName: "Estado",
      field: "estado",
      minWidth: 185,
      cellClass: "details-cell",
    },
    {
      headerName: "Acciones",
      field: 'id',
      cellRenderer: BtnCellLiquidationRender,
      cellRendererParams: {
        valuesCount: countList
      },
      colId: 'params',
      minWidth: 180,
      cellClass: "actions-cell"
    }
  ]);
  
  const onRowSelected = useCallback((event: any) => {
    if (currentLiquidaciones.includes(event.node.data) && !event.node.selected) {
      currentLiquidaciones.splice(currentLiquidaciones.indexOf(event.node.data), 1);
    }
  }, []);

  const onSelectionChanged = useCallback((event: any) => {
    var rowCount = event.api.getSelectedNodes().length;
    event.api.getSelectedNodes().forEach((d: any) => {
      if (!currentLiquidaciones.includes(d.data)) {
        currentLiquidaciones.push(d.data);
      }
    });

    if (rowCount >= 2)
      setShowPayAll(true);
    else
      setShowPayAll(false);
  }, []);
  
  const onPayAll = async () => {
    setModalPayShow(true);
  }

  useEffect(() => {
    if (liquidaciones && liquidaciones.data.length > 0) {
      setRowData(liquidaciones.data);
      if (liquidaciones.monto_total.length > 0) {
        const total = getNumberWithDecimal(liquidaciones.monto_total[0].liquidaciones_monto_total);
        setMontoTotal(total);
      }
      
      if (liquidaciones.fecha_pago.length > 0) {
        setFechaPago(liquidaciones.fecha_pago[0].fecha_formateado);
      }
    } else {
      setRowData([]);
      setMontoTotal('-');
      setFechaPago('-');
    }
  }, [ liquidaciones ]);

  const onGridReady = useCallback((params: any) => {
    if (liquidaciones && liquidaciones.data.length > 0) {

      setCountList(liquidaciones.data.length);
      setRowData(liquidaciones.data)
    }
  }, []);

  const downloadExcelFile = async (liquidacionId: number) => {
    showLoading(true);
    try {
      var convenio_Id;
      if (userconfig) {
        convenio_Id = userconfig[0].convenio_id
      } else {
        var res = JSON.parse(window.localStorage.getItem('userconfig')!);
        
        if (res !== undefined && res !== null) {
          convenio_Id = res[0].convenio_id;
        }
      }

      const { data } = await jtdApi.get<ILiquidacionExcelData>(`/api/liquidacion/exceldata`, {
        params: {
          convenioid: convenio_Id,
          liqid: liquidacionId
        }
      });

      if (data.data.length > 0) {
        buildExcelFile(data);
      } else {
        showLoading(false);
      }
    } catch (error) {
      showLoading(false);
    }
  }

  const buildExcelFile = (excelData: ILiquidacionExcelData) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData.data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Liquidación");

    XLSX.utils.sheet_add_aoa(worksheet, [
      ["IdPrestamo", "Nombre", "Cedula", "NIT", "#Cuota", "ValorCuota", "Abono", "Novedad", "Motivo", "ValorAPagar"],
    ]);

    XLSX.writeFile(workbook, `${excelData.filename}`, { compression: true });
    showLoading(false);
  }

  const showLoading = (value: boolean) => {
    dispatch(setShowLoadingState({ showloading: value }));
  }

  const goToDetalles = (id: any) => {
    if (id) {
      navigate(`/liquidacion/detalles/${id}`);
    }
  }

  const showPayment = (liqObj: any) => {
    if (currentLiquidaciones.length > 0) {
      setCurrentLiquidaciones([]);
    }

    setCurrentLiquidaciones([liqObj]);
    setModalPayShow(true);
  }

  return (
    <>
      <div className="container-liquidacion px-2">
        <div className="row justify-content-start header-info">
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12">
                  <p className="text text-uppercase">monto total</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12">
                  <p className="value text-uppercase">{montoTotal}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12">
                  <p className="text text-uppercase">fecha último pago</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12">
                  <p className="value text-uppercase">{fechaPago}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-table">
          <div className="container-grid">
            <Stack className="h-100 ag-theme-material">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowHeight={50}
                pagination={true}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                onRowSelected={onRowSelected}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onGridReady}
                isRowSelectable={(rowNode: IRowNode) => !rowNode.data.isblocked}
                context={{
                  goToDetalles,
                  showPayment,
                  downloadExcelFile
                }}
              ></AgGridReact>
            </Stack>
          </div>
        </div>

        <div className="btns-approve-all w-100">
          <div className="row mt-5">
            <div className="d-grid gap-2 d-flex justify-content-end">
              {
                showPayAll ? <button className="btn btn-secondary me-md-2 text-uppercase" type="button" onClick={() => onPayAll()}>Pagar todo</button>
                : null
              }
              </div>
          </div>
        </div>
      </div>
      <PagarModal show={modalPayShow} onHide={() => setModalPayShow(false)} data={currentLiquidaciones} />
    </>
  )
}

export default Liquidacion