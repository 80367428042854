import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { LiquidacionDetalles } from '../../interfaces/liquidacionInterface';
import { useMutation, useQueryClient } from 'react-query';
import { jtdApi } from '../../api/JTDApi';
import { useAppDispatch } from '../../hooks/hooks';
import { setShowLoadingState } from '../../redux/features/AppState.slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import UseNotification from '../../hooks/UseNotification';
import { TypeNotification } from '../../interfaces/messageNotificationInterface';
import { getNumberWithDecimal } from '../../utils/helper';
import useLiquidacionMotivosList from '../../hooks/useLiquidacionMotivosList';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: LiquidacionDetalles
}

interface IPayload {
    abonodesv: string;
    abono: string;
    motivo: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const LiquidacionNovedadModal = (props: Props) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const { userconfig } = useSelector((state: RootState) => state.appState);
    const { showNotification } = UseNotification();
    const [ balance, setBalance ] = useState<string>();
    const { liquidacionMotivosList } = useLiquidacionMotivosList();

    const schema = yup.object().shape({
        abonodesv: yup.string().required('Este campo es requerido'),
        abono: yup.number()
        .typeError('Ingrese solo números').positive('El valor debe ser positivo').when('abonodesv', {
            is: (abonodesv: any) => abonodesv === 'abono',
            then: (schema) => schema.required('Ingresa el valor del abono')
            .max(+props.data?.saldo_pendiente_capital!, 'El abono no puede ser mayor al saldo pendiente'),
            otherwise: (schema) => schema.optional()
            .max(+props.data?.saldo_pendiente_capital!, 'El abono no puede ser mayor al saldo pendiente'),
        }),
        motivo: yup.string().when('abonodesv', {
            is: (abonodesv: any) => abonodesv === 'abono',
            then: (schema) => schema.required('Selecciona un motivo de la lista desplegable'),
            otherwise: (schema) => schema.optional(),
        })
    });

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onSendNovedad = async (options: IPayload) => {
        showLoading(true);

        if (props.data) {
            const { id } = props.data;
            var userId;
            if (userconfig) {
                userId = userconfig[0].usuario_id;
            } else {
                var res = JSON.parse(window.localStorage.getItem('userconfig')!);
                userId = res[0].usuario_id;
            }

            const payload = {
                liqdetalleId: id,
                userid: userId,
                abonodesv: options.abonodesv,
                abono: options.abono,
                motivo: options.motivo
            }

            await jtdApi.post(`/api/liquidaciondetalles/novedad`, payload);
        } else {
            showLoading(false)
        }
    }

    const {mutate : sendNovedad} = useMutation(onSendNovedad, {
        onSuccess: () => {
            queryClient.invalidateQueries('getLiquidacionDetalles');
            showLoading(false);
            props.onHide();
        },
        onError: (err) => {
            showLoading(false);       
        }
    });

    const runNotification = (text: string) => {
        showNotification({
            show: true,
            message: text,
            typeNotification: TypeNotification.warning
        });
    }

    useEffect(() => {
        if (props.data?.saldo_pendiente_capital) {
            setBalance(getNumberWithDecimal(props.data?.saldo_pendiente_capital));
        }
    }, [props.data])
    
    return (
        
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pay-modal"
        >
            <Modal.Header closeButton className="border-bottom-0 text-center justify-content-center"></Modal.Header>
            <Modal.Body>
                <div className="card">
                    <div className="card-body">
                        <div className="content-item-detalles">
                            <h5 className="card-title m-0 d-title">Empleado</h5>
                            <div className="row mt-1">
                                <div className="col-12"><p className="val-items">{props.data?.nombrecompleto}</p></div>
                                <div className="col-12"><p className="val-items">{props.data?.cedula}</p></div>
                            </div>
                        </div>
                        <div className="content-item-detalles mt-2">
                            <h5 className="card-title m-0 d-title">Saldo pendiente</h5>
                            <div className="row">
                                <div className="col">
                                    <span className="val-items">{ balance }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pay-trans">
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                abonodesv: 'abono',
                                abono: '',
                                motivo: '',
                            }}
                            initialTouched={{
                                abonodesv: true,
                                abono: true,
                                motivo: true,
                            }}
                            onSubmit={async (values) => {
                                await sleep(300);
                                sendNovedad(values);
                            }}
                        >
                            {({ values, touched, errors, setFieldValue, handleBlur, isSubmitting, isValid, dirty }) => (
                                <Form>
                                    <div className="mt-3 mb-3 row items-btn-radio" role="group" aria-labelledby="my-radio-group">
                                        <div className="col-6">
                                            <label>
                                                <Field type="radio" name="abonodesv" value="abono" /> <span>Abono</span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label>
                                                <Field type="radio" name="abonodesv" value="desvincular" /> <span>Desvincular</span>
                                            </label>
                                        </div>
                                        {errors.abonodesv && touched.abonodesv ? <div className="py-2 text-center text-danger">{errors.abonodesv}</div> : null}
                                    </div>
                                    <div className="content-item-payways">
                                        <div className="abono">
                                            <div className="row">
                                                <div className="col-12 total"><span>Abono</span></div>
                                                <div className="col-12 value">
                                                    <input
                                                        id="abono-1"
                                                        className="form-control"
                                                        placeholder="$"
                                                        name="abono"
                                                        value={values.abono}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            const { value } = e.target;
                                                            const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                            if (!value || regex.test(value.toString())) {
                                                                setFieldValue("abono", value);
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                {errors.abono && touched.abono ? <div className="py-2 text-center text-danger error-message-font">{errors.abono}</div> : null}
                                            </div>
                                            {
                                                (values.abonodesv === 'abono') ? 
                                                    <div className="row desvincular">
                                                        <div className="col-12 total"><span>Motivo</span></div>
                                                        <div className="col-12 value">
                                                            <Field
                                                                component="select"
                                                                id="motivo"
                                                                name="motivo"
                                                                multiple={false}
                                                                className="form-select"
                                                            >
                                                                <option value=""></option>
                                                                {
                                                                    liquidacionMotivosList ? liquidacionMotivosList.data.map((x, ind) => {
                                                                        return (
                                                                            <option key={ind} value={x.id}>{x.nombre}</option>
                                                                        )
                                                                    }): null
                                                                }
                                                            </Field>
                                                        </div>
                                                        {errors.motivo && touched.motivo ? <div className="py-2 text-center text-danger error-message-font">{errors.motivo}</div> : null}
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="mx-auto approve-btn-actions btn-submit text-center mt-4">
                                        <Button className="btn me-2 text-uppercase" type="submit" disabled={isSubmitting || (!isValid || !dirty)}>Aceptar</Button>
                                        <Button className="btn text-uppercase" onClick={props.onHide}>Cancelar</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LiquidacionNovedadModal