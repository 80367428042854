import { useEffect, useState } from 'react';
import { jtdApi } from '../api/JTDApi';
import { IMediosPago } from '../interfaces/liquidacionInterface';

const useMediosPagoList = () => {
    const [ mediosPagoList, setMediosPagoList ] = useState<IMediosPago>();
    
    const getList = async() => {
        try {
            await jtdApi.get<IMediosPago>(`/api/liquidacion/mediospago`).then((response) => {
                setMediosPagoList(response.data);
            });
        } catch (error) {}
    }

    useEffect(() => {
        getList();
    }, [])
    
    return {
        mediosPagoList
    }
}

export default useMediosPagoList