import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from 'react-bootstrap';
import { ColDef } from 'ag-grid-community';
import BtnCellDescargarRender from '../components/BtnCellDescargarRender';
import { useLiquidacionHistorialPagos } from '../hooks/useLiquidacionHistorialPagos';
import { HistorialPagosData, ILiquidacionExcelData } from '../interfaces/liquidacionInterface';
import { getNumberWithDecimal } from '../utils/helper';
import { useAppDispatch } from '../hooks/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { setShowLoadingState } from '../redux/features/AppState.slice';
import { jtdApi } from '../api/JTDApi';
import * as XLSX from "xlsx";

const HistorialPagos = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { historialpagos } = useLiquidacionHistorialPagos();
  const [ rowData, setRowData ] = useState<HistorialPagosData[]>([]);
  const [ montoTotal, setMontoTotal ] = useState<string>('-');
  const dispatch = useAppDispatch();
  const { userconfig } = useSelector((state: RootState) => state.appState);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const showLoading = (value: boolean) => {
    dispatch(setShowLoadingState({ showloading: value }));
  }

  const buildExcelFile = (excelData: ILiquidacionExcelData) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData.data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Liquidación");

    XLSX.utils.sheet_add_aoa(worksheet, [
      ["IdPrestamo", "Nombre", "Cedula", "NIT", "#Cuota", "ValorCuota", "Abono", "Novedad", "Motivo", "ValorAPagar"],
    ]);

    XLSX.writeFile(workbook, `${excelData.filename}`, { compression: true });
    showLoading(false);
  }

  const downloadExcelFile = async (liquidacionId: number) => {
    showLoading(true);
    try {
      var convenio_Id;
      if (userconfig) {
        convenio_Id = userconfig[0].convenio_id
      } else {
        var res = JSON.parse(window.localStorage.getItem('userconfig')!);
        
        if (res !== undefined && res !== null) {
          convenio_Id = res[0].convenio_id;
        }
      }

      const { data } = await jtdApi.get<ILiquidacionExcelData>(`/api/liquidacion/exceldata`, {
        params: {
          convenioid: convenio_Id,
          liqid: liquidacionId
        }
      });

      if (data.data.length > 0) {
        buildExcelFile(data);
      } else {
        showLoading(false);
      }
    } catch (error) {
      showLoading(false);
    }
  }

  const capitalizeFirstLowercase= (params: any) => {
    if (params.value !== undefined && params.value !== '' && params.value !== null) {
      return params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase()
    } else {
      return ''
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Mes",
      field: "mes_liquidacion",
      minWidth: 205,
      valueFormatter: (params: any) => capitalizeFirstLowercase(params),
      cellClass: "details-cell",
    },
    {
      headerName: "NIT",
      field: "NIT",
      minWidth: 135,
      cellClass: "details-cell",
    },
    {
      headerName: "Frecuencia",
      field: "frecuencia",
      minWidth: 165,
      cellClass: "details-cell",
    },
    {
      headerName: "Valor",
      field: "valor_pago",
      minWidth: 170,
      valueFormatter: (params: any) => {
        if (params.value !== undefined && params.value !== '' && params.value !== null) {
          return getNumberWithDecimal(params.value);
        } else {
          return '-'
        }
      },
      cellClass: "details-cell",
    },
    {
      headerName: "Fecha pago",
      field: "fecha_pago",
      minWidth: 175,
      cellClass: "details-cell",
    },
    {
      headerName: "Acciones",
      field: 'id',
      cellRenderer: BtnCellDescargarRender,
      minWidth: 180,
      cellClass: "actions-cell"
    }
  ]);

  useEffect(() => {
    if (historialpagos && historialpagos.data.length > 0) {
      setRowData(historialpagos.data);
      
      const total = getNumberWithDecimal(historialpagos.monto_total[0].historial_monto);
      setMontoTotal(total);
    } else {
      setRowData([]);
      setMontoTotal('-');
    }
  }, [ historialpagos ]);

  const onGridReady = useCallback((params: any) => {
    if (historialpagos && historialpagos.data.length > 0) {
      setRowData(historialpagos.data)
    }
  }, []);
  
  return (
    <>
      <div className="container-historial-pago px-2">
        <div className="row justify-content-start header-info">
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12">
                  <p className="text text-uppercase">monto total</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12">
                  <p className="value text-uppercase">{montoTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-table">
          <div className="container-grid">
            <Stack className="h-100 ag-theme-material">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowHeight={50}
                pagination={true}
                onGridReady={onGridReady}
                context={{
                  downloadExcelFile
                }}
              ></AgGridReact>
            </Stack>
          </div>
        </div>
      </div>
    </>
  )
}

export default HistorialPagos