import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { DataNovedad } from '../../interfaces/novedadesInterface';
import './styles.scss';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: DataNovedad
}

const DetallesModal = (props: Props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="details-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {
                    props.data ? (
                        <Table striped bordered hover className="details-table">
                            <thead>
                                <tr>
                                <th><span className="text-uppercase">celular</span></th>
                                <th><span className="text-uppercase">Correo electrónico</span></th>
                                <th><span className="text-uppercase"># de contrato</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>{props.data.celular}</td>
                                <td>{props.data.email}</td>
                                <td>{props.data.contrato}</td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : <p>No hay datos para mostrar</p>
                }
            </Modal.Body>
        </Modal>
    )
}

export default DetallesModal