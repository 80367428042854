import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { DataNovedadDesvinculados } from '../interfaces/novedadesInterface';
import { getNumberWithDecimal } from '../utils/helper';
import { useDesvinculados } from '../hooks/useDesvinculados';

type Props = {}

const TabDesvinculados = (props: Props) => {
    const gridRef = useRef<AgGridReact>(null);
    const [ rowData, setRowData ] = useState<DataNovedadDesvinculados[]>([]);
    const { desvinculados } = useDesvinculados();

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const formatValueCurrency = (params: any) => {
        if (params.value !== undefined && params.value !== '' && params.value !== null) {
          return getNumberWithDecimal(params.value);
        } else {
          return '-'
        }
    }

    const [columnDefs] = useState([
        {
            headerName: "Nombre",
            field: "nombrecompleto",
            minWidth: 225,
            cellClass: "details-cell",
        },
        {
            headerName: "Cédula",
            field: "cedula",
            minWidth: 130,
            cellClass: "details-cell",
        },
        {
            headerName: "NIT",
            field: "nit",
            minWidth: 145,
            cellClass: "details-cell",
        },
        {
            headerName: "Monto solicitado",
            field: "montosolicitado",
            minWidth: 160,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Abono por desvinculación",
            field: "abonodesvinculacion",
            minWidth: 200,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Total pagado",
            field: "totalpagado",
            minWidth: 150,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Saldo pendiente",
            field: "saldopendiente",
            minWidth: 180,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Fecha desvinculación",
            field: "fechadesvinculacion",
            minWidth: 200,
            cellClass: "details-cell",
        }
    ]);

    const onGridReady = useCallback((params: any) => {
        if (desvinculados && desvinculados.data.length > 0) {
            setRowData(desvinculados.data)
        }
    }, []);

    useEffect(() => {
        if (desvinculados && desvinculados.data.length > 0) {
            setRowData(desvinculados.data)
        } else {
            setRowData([]);
        }
    }, [ desvinculados ])

    return (
        <Card body className="rounded-0 border-0" style={{ height: "calc(95vh - 300px)" }}>
            <Stack className="h-100 ag-theme-material">
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    rowHeight={50}
                    pagination={true}
                    onGridReady={onGridReady}
                ></AgGridReact>
            </Stack>
        </Card>
    )
}

export default TabDesvinculados