import { useEffect, useState } from 'react';
import { jtdApi } from '../api/JTDApi';
import { IMotivos } from '../interfaces/liquidacionInterface';

const useLiquidacionMotivosList = () => {
    const [ liquidacionMotivosList, setLiquidacionMotivosList ] = useState<IMotivos>();
    
    const getList = async() => {
        try {
            await jtdApi.get<IMotivos>(`/api/liquidacion/motivos`).then((response) => {
                setLiquidacionMotivosList(response.data);
            });
        } catch (error) {}
    }

    useEffect(() => {
        getList();
    }, [])
    
    return {
        liquidacionMotivosList
    }
}

export default useLiquidacionMotivosList