import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

const LogoSite = () => {
    return (
        <Container className="d-flex align-items-center justify-content-center mb-4 mt-4">
            <div style={{ backgroundColor: 'rgba(196, 196, 196, 0.16)', borderRadius: '50%', width: '70%', padding: '1.2rem' }}>
                <Image style={{ width: '100%' }} src="/images/v960-ning-30 2.png" />
            </div>
        </Container>
    )
}

export default LogoSite;
