import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TabAprobados from '../components/TabAprobados';
import TabDesvinculados from '../components/TabDesvinculados';
import TabPendientes from '../components/TabPendientes';
import TabRechazados from '../components/TabRechazados';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { StoreStatus } from '../interfaces/cognitoInterface';
import { setShowLoadingState, setUserConfig } from '../redux/features/AppState.slice';
import { RootState } from '../redux/store';
import './styles.scss';

type Props = {}

const Novedades = (props: Props) => {
  const [key, setKey] = useState<string | null>('pendientes');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isAuthenticated, status } = useAppSelector((state) => state.auth);
  const { userconfig } = useSelector((state: RootState) => state.appState);
  
  useEffect(() => {
    var res = JSON.parse(window.localStorage.getItem('userconfig')!);

    if (res !== undefined && res !== null) {
      dispatch(setUserConfig({ user: res }));
    }
  }, []);

  useEffect(() => {
    if (userconfig !== undefined && userconfig !== null)
      window.localStorage.setItem('userconfig', JSON.stringify(userconfig));
  }, [userconfig]);

  useEffect(() => {
    dispatch(setShowLoadingState({ showloading: false }));

    if (!isAuthenticated && [StoreStatus.Succeeded, StoreStatus.Failed].includes(status)) {
      navigate('/')
    }
  }, [isAuthenticated, navigate, status]);

  return (
    <>
      <Tabs
        id="novedades-tab"
        className="mb-3 tab-novedades text-uppercase text-start"
        onSelect={(k) => setKey(k)}
        fill
      >
        <Tab eventKey="pendientes" title="Pendientes">
          <TabPendientes />
        </Tab>
        <Tab eventKey="aprobados" title="Aprobados">
          <TabAprobados />
        </Tab>
        <Tab eventKey="rechazados" title="Rechazados">
          <TabRechazados />
        </Tab>
        <Tab eventKey="desvinculados" title="Desvinculados">
          <TabDesvinculados />
        </Tab>
      </Tabs>
    </>
  )
}

export default Novedades