import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import Stack from 'react-bootstrap/Stack';
import { AgGridReact } from 'ag-grid-react';
import BtnCellDetailsRender from '../components/BtnCellDetailsRender';
import LiquidacionNovedadModal from '../components/widgets/LiquidacionNovedadModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiquidacionDetalles } from '../hooks/useLiquidacionDetalles';
import { LiquidacionDetalles } from '../interfaces/liquidacionInterface';
import { useAppDispatch } from '../hooks/hooks';
import { setShowLoadingState } from '../redux/features/AppState.slice';
import UseNotification from '../hooks/UseNotification';
import { TypeNotification } from '../interfaces/messageNotificationInterface';
import DeshacerModal from '../components/widgets/DeshacerModal';
import { getNumberWithDecimal } from '../utils/helper';

type Props = {}

const DetallesLiquidacion = (props: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ modalNoveltyShow, setModalNoveltyShow ] = useState(false);
  const [ modalUndoShow, setModalUndoShow ] = useState(false);
  const { liquidacionId } = useParams();
  const { liquidacionDetalles } = useLiquidacionDetalles(+liquidacionId!);
  const [ rowData, setRowData ] = useState<LiquidacionDetalles[]>([]);
  const [ settlementName, setSettlementName ] = useState<string>();
  const [ amount, setAmount ] = useState<string>();
  const [ paymentLimit, setPaymentLimit ] = useState<string>();
  const [ monthSettlement, setMonthSettlement ] = useState<string>();
  const [ currentliquidacionDetalles, setCurrentLiquidacionDetalles ] = useState<LiquidacionDetalles>();
  const { showNotification } = UseNotification();
  
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const formatValueCurrency = (params: any) => {
    if (params.value !== undefined && params.value !== '' && params.value !== null) {
      return getNumberWithDecimal(params.value);
    } else {
      return '-'
    }
  }

  const formatValueNovedad = (params: any) => {
    if (params.value !== undefined && params.value !== '' && params.value !== null) {
      var status = '-';
      if (params.value === 'desvinculacion') {
        status = 'Desvinculación';
      } else if (params.value === 'abono_extraordinario') {
        status = 'Abono extraordinario';
      }
      return status;
    } else {
      return '-'
    }
  }

  const [columnDefs] = useState([
    {
      headerName: "Nombre",
      field: "nombrecompleto",
      minWidth: 205,
      cellClass: "details-cell",
    },
    {
      headerName: "Cédula",
      field: "cedula",
      minWidth: 135,
      cellClass: "details-cell",
    },
    {
      headerName: "NIT",
      field: "NIT",
      minWidth: 135,
      cellClass: "details-cell",
    },
    {
      headerName: "# cuota",
      field: "numero_cuota",
      minWidth: 135,
      cellClass: "details-cell",
    },
    {
      headerName: "Valor cuota",
      field: "valor_cuota",
      minWidth: 175,
      valueFormatter: (params: any) => formatValueCurrency(params),
      cellClass: "details-cell",
    },
    {
      headerName: "Abono",
      field: "abono",
      minWidth: 150,
      valueFormatter: (params: any) => formatValueCurrency(params),
      cellClass: "details-cell",
    },
    {
      headerName: "Novedad",
      field: "novedad",
      minWidth: 190,
      valueFormatter: (params: any) => formatValueNovedad(params),
      cellClass: "details-cell",
    },
    {
      headerName: "Valor a pagar",
      field: "valor_a_pagar",
      minWidth: 195,
      valueFormatter: (params: any) => formatValueCurrency(params),
      cellClass: "details-cell",
    },
    {
      headerName: "Acciones",
      field: 'id',
      cellRenderer: BtnCellDetailsRender,
      minWidth: 150,
      cellClass: "actions-cell"
    }
  ]);

  const showLoading = (value: boolean) => {
    dispatch(setShowLoadingState({ showloading: value }));
  }

  const addNovelty = (liqObj: any) => {
    setCurrentLiquidacionDetalles(liqObj);
    setModalNoveltyShow(true);
  }

  const undoNovelty = (liqObj: any) => {
    setCurrentLiquidacionDetalles(liqObj);
    setModalUndoShow(true);
  }

  useEffect(() => {
    if (liquidacionDetalles && liquidacionDetalles.data.length > 0) {
      setRowData(liquidacionDetalles.data);
      setSettlementName(liquidacionDetalles.montoinfo[0].periodo_pago);
      const total = getNumberWithDecimal(liquidacionDetalles.montoinfo[0].monto);
      setAmount(total);
      setPaymentLimit(liquidacionDetalles.montoinfo[0].fecha_limite_pago);
      setMonthSettlement(liquidacionDetalles.montoinfo[0].liquidacion_mes);
    } else {
      setRowData([]);
    }
  }, [ liquidacionDetalles ]);

  const onGridReady = useCallback((params: any) => {
    if (liquidacionDetalles && liquidacionDetalles.data.length > 0) {
      setRowData(liquidacionDetalles.data)
    }
  }, []);

  const runNotification = (text: string, typeNot: TypeNotification = TypeNotification.warning) => {
    showNotification({
      show: true,
      message: text,
      typeNotification: typeNot
    });
  }

  return (
    <>
      <div className="container-liquidacion-detalle px-2">
        <div className="row justify-content-start header-info">
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12 container-month">
                  <p className="value-month text-uppercase text-center">{monthSettlement}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12">
                  <p className="text text-uppercase">monto total</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12">
                  <p className="value text-uppercase">{amount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="content-card">
              <div className="row">
                <div className="col-12">
                  <p className="text text-uppercase">fecha limite de pago</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12">
                  <p className="value text-uppercase">{paymentLimit}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-table">
          <div className="container-grid">
            <Stack className="h-100 ag-theme-material">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowHeight={50}
                pagination={true}
                onGridReady={onGridReady}
                context={{
                  addNovelty,
                  undoNovelty
                }}
              ></AgGridReact>
            </Stack>
          </div>
        </div>
        <div className="btns-approve-all w-100">
          <div className="row mt-4 mb-3">
            <div className="d-grid gap-2 d-flex justify-content-end">
              <button className="btn btn-secondary me-md-2 text-uppercase" type="button" onClick={() => navigate('/liquidacion')}>
                <i className="bi bi-arrow-left"></i> Regresar
              </button>
            </div>
          </div>
        </div>
      </div>
      <LiquidacionNovedadModal show={modalNoveltyShow} onHide={() => setModalNoveltyShow(false)} data={currentliquidacionDetalles} />
      <DeshacerModal show={modalUndoShow} onHide={() => setModalUndoShow(false)} data={currentliquidacionDetalles} />
    </>
  )
}

export default DetallesLiquidacion