import axios from "axios";
import { Auth } from '@aws-amplify/auth';

const baseURL = process.env.REACT_APP_API_URL;

const jtdApi = axios.create({ baseURL: baseURL });

jtdApi.interceptors.request.use(
    async (config) => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        
        if (token) {
            config.headers.Authorization = `${token}`;
        }
 
        return config;
    }
);

export {
    jtdApi
};