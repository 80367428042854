import numbro from 'numbro';

const currencyFormatter = (currency: number, sign: string) => {
    var sansDec = Number(currency).toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return sign + ` ${formatted}`;
}

const getNumberWithoutDecimal = (val: string) => {

    return numbro(val).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        spaceSeparated: true
    });
}

const getNumberWithDecimal = (val: string, decimal: number = 2) => {

    return numbro(val).formatCurrency({
        thousandSeparated: true,
        mantissa: decimal,
        spaceSeparated: true
    });
}

export {
    currencyFormatter,
    getNumberWithoutDecimal,
    getNumberWithDecimal
}