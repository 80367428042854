import { createContext, useCallback, useState } from 'react';
import { TypeNotification } from '../../interfaces/messageNotificationInterface';
import { INotification, NotificationContextProps } from '../../interfaces/notification';

export const NotificationContext = createContext({} as NotificationContextProps);

export default function NotificationContextProvider({ children }: any) {
    const [ notification, setNotification ] = useState<INotification>({
        show: false,
        typeNotification: TypeNotification.warning
    });
    
    const onClose = () => {
        setNotification({ 
            show: false,
            message: notification?.message,
            typeNotification: notification!.typeNotification
        });
    }

    const addNotification = (noty: INotification) => {
        setNotification(noty);
    }

    const contextValue = {
        notification: notification!,
        showNotification: useCallback((notification: INotification) => addNotification(notification), []),
        onClose: useCallback(() => onClose(), [])
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
}