import { ColDef, IRowNode } from "ag-grid-community";

type Props = {
  colDef: ColDef;
  node: IRowNode;
  context: any
}

const BtnCellRenderer = (props: Props) => {

  const invokeParentApprove = () => {
    props.context.showApprove(props.node.data);
  }

  const invokeParentReject = () => {
    props.context.showReject(props.node.data);
  }

  const invokeParentEdit = () => {
    props.context.showEdit(props.node.data);
  }

  return (
    <div className="btn-actions">
      <button type="button" className="btn px-1 py-0 fs-5" onClick={() => invokeParentApprove()}>
        <i className="bi bi-check-circle" aria-hidden="true"></i>
        <br />
        <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
          Aprobar
        </p>
      </button>
      <button type="button" className="btn px-1 py-0 fs-5" onClick={() => invokeParentReject()}>
        <i className="bi bi-x-circle" aria-hidden="true"></i>
        <br />
        <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
          Rechazar
        </p>
      </button>
      <button type="button" className="btn px-1 py-0 edit" onClick={() => invokeParentEdit()}>
        <i className="bi" aria-hidden="true"></i>
        <br />
        <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
          Modificar
        </p>
      </button>
    </div>
  )
}

export default BtnCellRenderer