import { ReactNode, useEffect } from "react"
import { setAppState } from "../../redux/features/AppState.slice"
import { useAppDispatch } from '../../hooks/hooks';

type Props = {
  state?: string,
  pageName: string,
  children: ReactNode,
  showLoading: boolean
}

const PageWrapper = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setAppState({ pagestate: props.state, pagename: props.pageName, showloading: props.showLoading }));
    }
  
  }, [dispatch, props])
  
  return (
    <>{props.children}</>
  )
}

export default PageWrapper;