import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { StoreStatus } from '../../interfaces/cognitoInterface';
import {
  refreshToken,
  signIn,
  signOut
} from './Auth.services';
import { AuthState } from './Auth.types';

const initialState: AuthState = {
  isAuthenticated: false,
  message: '',
  status: StoreStatus.Idle,
  user: null,
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({
      ...state,
      isAuthenticated: true,
      user: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => ({
      ...state,
      status: StoreStatus.Succeeded,
      isAuthenticated: true,
      user: payload,
    }))
    builder.addCase(signOut.fulfilled, () => initialState)
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload,
      isAuthenticated: true,
      status: StoreStatus.Succeeded,
    }))
    builder.addCase(refreshToken.rejected, (state) => ({
      ...state,
      status: StoreStatus.Failed,
    }))

    // Handle pending & rejected requests
    builder.addMatcher(
      isAnyOf(
        signIn.pending,
        signOut.pending,
        refreshToken.pending,
      ),
      () => ({
        ...initialState,
        status: StoreStatus.Loading,
      }),
    )
    builder.addMatcher(
      isAnyOf(
        signIn.rejected,
        signOut.rejected,
      ),
      (state, { error }) => ({
        ...state,
        status: StoreStatus.Failed,
        message: error.message || '',
      }),
    )
  },
})

export const { setUser } = AuthSlice.actions

export default AuthSlice.reducer