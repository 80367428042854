import { useContext } from 'react';
import { NotificationContext } from '../context/notificationContext/NotificationContextProvider';

const UseNotification = () => {

    const { notification, onClose, showNotification } = useContext(NotificationContext);
    
    return {notification, onClose, showNotification};
}

export default UseNotification;