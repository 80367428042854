import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Liquidaciones } from '../../interfaces/liquidacionInterface';
import { getNumberWithDecimal } from '../../utils/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { jtdApi } from '../../api/JTDApi';
import { useMutation, useQueryClient } from 'react-query';
import { useAppDispatch } from '../../hooks/hooks';
import { setShowLoadingState } from '../../redux/features/AppState.slice';
import useMediosPagoList from '../../hooks/useMediosPagoList';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: Liquidaciones[]
}

interface IPayload {
    payway: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const PagarModal = (props: Props) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const { userconfig } = useSelector((state: RootState) => state.appState);
    const [ montoTotal, setMontoTotal ] = useState<string>();
    const { mediosPagoList } = useMediosPagoList();
    const schema = yup.object().shape({
        payway: yup.string().required('Este campo es requerido')
    });

    const formatValueCurrency = (value: any) => {
        if (value !== undefined && value !== '' && value !== null) {
          return getNumberWithDecimal(value);
        } else {
          return '-'
        }
    }

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onPayment = async (method: IPayload) => {
        showLoading(true);        
        try {
            if (props.data) {
                var convenio_Id;
                var userId;
                if (userconfig) {
                    convenio_Id = userconfig[0].convenio_id
                    userId = userconfig[0].usuario_id;
                } else {
                    var res = JSON.parse(window.localStorage.getItem('userconfig')!);
                    
                    if (res !== undefined && res !== null) {
                        convenio_Id = res[0].convenio_id;
                        userId = res[0].usuario_id;
                    }
                }

                const liqIds = props.data.map((x) => {
                    return { id: x.id };
                });

                const payload = {
                    convenioid: convenio_Id,
                    liqids: liqIds,
                    userid: userId,
                    mediopagoid: +method.payway
                }

                return await jtdApi.post(`/api/liquidacion/pagar`, payload);
            } else {
                // runNotification('Algo salio mal, vuelva a intentarlo');
                showLoading(false)
            }
        } catch (error) {
            showLoading(false);
        }
    }

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    const {mutate : runPayment} = useMutation(onPayment, {
        onSuccess: (result: any) => {
            const currentData = result.data;
            // validate if the link is not null or was generated
            openInNewTab(currentData.smartLinkUrl);
            queryClient.invalidateQueries('getDesvinculados');
            queryClient.invalidateQueries('getLiquidaciones');
            showLoading(false);
            props.onHide();
        },
        onError: (err: any) => {
            // runNotification(err.message ? err.message : 'Algo salio mal, vuelva a intentarlo');
            showLoading(false);
        }
    });

    useEffect(() => {
        if (props.data) {
            const total = props.data.map((x) => {
                let num = x.valor_total_a_pagar !== undefined ? +x.valor_total_a_pagar : 0;
                return num;
            });
            
            const totalSum = total.reduce((acc, val) => {
                return acc + val;
            }, 0);            

            setMontoTotal(getNumberWithDecimal(totalSum.toString()));
        }
    }, [props])
    
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pay-modal"
        >
            <Modal.Header closeButton className="border-bottom-0 text-center justify-content-center">
                <div className="col-12">
                    <h4 className="text-center">Pagar</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title m-0">Detalles</h5>
                        <Table striped bordered hover className="pay-details-table">
                            <tbody>
                                {
                                    props.data?.map((x, i) => (
                                        <tr key={i}>
                                            <td><p>{x.mes_liquidacion}: {x.NIT}</p></td>
                                            <td><p>{formatValueCurrency(x.valor_total_a_pagar)}</p></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="card-footer text-body-secondary">
                        <div className="row">
                            <div className="col-4 total">Total:</div>
                            <div className="col-8 value">{montoTotal}</div>
                        </div>
                    </div>
                    <div className="pay-trans">
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                payway: mediosPagoList?.data.find(p => p.nombre === 'Pago en línea PSE')?.id.toString() ?? ''
                            }}
                            initialTouched={{
                                payway: true
                            }}
                            onSubmit={async (values) => {
                                await sleep(300);
                                runPayment(values);
                            }}
                        >
                            {({ handleSubmit, handleChange, values, touched, isValidating, errors, isSubmitting, isValid, dirty }) => (
                                <Form>
                                    <h4 className="card-title text-center m-0">Medios de pago</h4>
                                    <div className="mt-3 mb-3" role="group" aria-labelledby="my-radio-group">
                                        {
                                            mediosPagoList ? mediosPagoList.data.map((x, ind) => {
                                                return (
                                                    <label key={ind} className="w-100">
                                                        <Field type="radio" name="payway" value={x.id.toString()} onChange={handleChange} /> {x.nombre}
                                                    </label>
                                                )
                                            }) : null
                                        }
                                        {errors.payway && touched.payway ? <div className="py-2 text-center text-danger">{errors.payway}</div> : null}
                                    </div>
                                    <div className="line"></div>
                                    <div className="note">
                                        <p>
                                            Te llevaremos a la pasarela de pago Kushki para que realices tu pago. Después de completarlo, puede tomarnos un momento actualizar el estado a la liquidación.
                                        </p>
                                    </div>

                                    <div className="mx-auto approve-btn-actions btn-submit text-center">
                                        {
                                            (!!values.payway) && <Button className="btn me-2 text-uppercase" type="submit" disabled={isSubmitting}>Pagar</Button>
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PagarModal