
export interface INovedades {
    description: string;
    data:        DataNovedad[];
    cuotaminima: number;
}

export interface DataNovedad {
    id:               number;
    nombrecompleto:   string;
    cedula:           string;
    nit:              string;
    montopreaprobado: string;
    cuota:            string;
    plazo:            string;
    fechasolicitud:   string;
    contrato:         string;
    email:            string;
    celular:          string;
}

export interface IAprobados {
    description: string;
    data:        DataNovedadAprobados[];
}

export interface DataNovedadAprobados {
    id:               number;
    nombrecompleto:   string;
    cedula:           string;
    nit:              string;
    montopreaprobado: string;
    plazo:            string;
    cuotapreaprobada: string;
    cuotaautorizada:  string;
    fechaaprobacion:  string;
    saldorestante:    string;
    contrato:         string;
}

export interface IRechazados {
    description: string;
    data:        DataNovedadRechazados[];
}

export interface DataNovedadRechazados {
    id:               number;
    nombrecompleto:   string;
    cedula:           string;
    nit:              string;
    montopreaprobado: string;
    cuota:            string;
    plazo:            string;
    fecharechazo:     string;
    motivo:           string;
    contrato:         string;
}

export interface INovedadesIds {
    id: number;
}

export interface IDesvinculados {
    description: string;
    data:        DataNovedadDesvinculados[];
}

export interface DataNovedadDesvinculados {
    id:                   number;
    nombrecompleto:       string;
    cedula:               string;
    nit:                  string;
    montosolicitado:      string;
    abonodesvinculacion:  string;
    totalpagado:          string;
    saldopendiente:       string;
    fechadesvinculacion:  string;
}

export enum NovedadDecision {
    Approved = 'APPROVED',
    Reject = 'REJECT',
    Modify = 'MODIFY'
}

export interface INovedadCollection {
    description: string;
    data:        NovedadCollectio[];
}

export interface NovedadCollectio {
    id:          number;
    nombre:      string;
    descripcion: string;
    modulo:      string;
}
