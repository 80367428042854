import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMutation, useQueryClient } from 'react-query';
import { jtdApi } from '../../api/JTDApi';
import { useAppDispatch } from '../../hooks/hooks';
import { DataNovedad, INovedadesIds, NovedadDecision } from '../../interfaces/novedadesInterface';
import { setShowLoadingState } from '../../redux/features/AppState.slice';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: DataNovedad[]
}

const AprobarTodasSolicitudModal = (props: Props) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onSendApproveAll = async () => {
        showLoading(true);

        if (props.data && props.data.length > 0) {
            let allNovIds: INovedadesIds[] = props.data.map((x) => {
                return {
                    id: x.id
                }
            });

            const decisions = props.data.map((x) => {
                return {
                    "decision": NovedadDecision.Approved,
                    "installmentValue": 0,
                    "identification": x.cedula,
                    "loanId": +x.contrato
                }
            });

            const payload = {
                "decisions": decisions,
                "novedesIdspend": allNovIds
            }

            await jtdApi.post(`/api/aprobarnovedades`, payload);
        } else {
            showLoading(false)
        }
    }

    const {mutate : approveAllNovedades} = useMutation(onSendApproveAll, {
        onSuccess: () => {
            queryClient.invalidateQueries('getNovedades');
            queryClient.invalidateQueries('getAprobados');
            showLoading(false);
            props.onHide();
        },
        onError: (err) => {
            showLoading(false);       
        }
    });

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="approve-modal"
        >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>
                <div className="container">
                    <p className="text-center">¿Apruebas todas las solicitudes?</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <div className="mx-auto approve-btn-actions btn-submit">
                    <Button className="btn me-2 text-uppercase" onClick={() => approveAllNovedades()}>Si</Button>
                    <Button className="btn text-uppercase" onClick={props.onHide}>Cancelar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AprobarTodasSolicitudModal