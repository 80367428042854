import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { jtdApi } from '../api/JTDApi';
import { IAprobados } from '../interfaces/novedadesInterface';
import { RootState } from '../redux/store';

export const useAprobados = () => {
    const { userconfig } = useSelector((state: RootState) => state.appState);
    
    const fetchAprobados = async () => {
        var convenio_Id;
        var userId;
        if (userconfig) {
            convenio_Id = userconfig[0].convenio_id;
            userId = userconfig[0].usuario_id
        } else {
            var res = JSON.parse(window.localStorage.getItem('userconfig')!);
            if (res !== undefined && res !== null) {
                convenio_Id = res[0].convenio_id;
                userId = res[0].usuario_id
            }
        }

        const { data } = await jtdApi.get<IAprobados>(`/api/novedadesaprobados`, {
            params: {
                convenioid: convenio_Id,
                userid: userId
            }
        });

        return data;
    }

    const { data, error, isError, isLoading } = useQuery("getAprobados", fetchAprobados);
      
    return {
        aprobados: data,
        error,
        isError,
        isLoading
    }
}