import Card from 'react-bootstrap/Card';
import MessageNotification from '../components/common/MessageNotification';
import LoginForm from '../components/LoginForm';
import LogoSite from '../components/LogoSite';

const Login = () => {
    return (
        <>
            <div className="login-screen">
                <div className="row d-flex vh-100 align-items-center justify-content-center align-middle">
                    <div className="content">
                        <Card>
                            <Card.Body>
                                <LogoSite />
                                <LoginForm />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <MessageNotification />
        </>
    )
}

export default Login;
