import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { signIn, signOut } from '../redux/features/Auth.services';
import { StoreStatus } from '../interfaces/cognitoInterface';
import { setShowLoadingState, setUserConfig } from '../redux/features/AppState.slice';
import { jtdApi } from '../api/JTDApi';
import { useMutation } from 'react-query';
import { IUserConfig } from '../interfaces/userInterface';
import { TypeNotification } from '../interfaces/messageNotificationInterface';
import UseNotification from '../hooks/UseNotification';

interface SignInForm {
    username: string
    password: string
}

function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, status, message, isAuthenticated } = useAppSelector((state) => state.auth);

    const schema = yup.object().shape({
        username: yup.string().required('El Usuario es un campo requerido'),
        password: yup.string().required('La contraseña es un campo requerido'),
    });

    const [formData, setFormData] = useState<SignInForm>();
    const [severity, setSeverity] = useState<string>();
    const { showNotification } = UseNotification();

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const OnUserConvenio = async () => {
        if (user) {
            const { data } = await jtdApi.get<IUserConfig>(`/api/usuarioconvenio?email=${user.email}`);            

            return data.data;
        } else {
            showLoading(false);
        }
    }

    const {mutate : getUserConvenio} = useMutation(OnUserConvenio, {
        onSuccess: (result) => {
            if (result) {
                if (result.length === 0) {
                    runNotification('No tienes un convenio o empleador asociado');
                    dispatch(signOut()).then(() => navigate('/', { replace: true }))
                } else {
                    window.localStorage.setItem('userconfig', JSON.stringify(result));
                    dispatch(setUserConfig({ user: result }));
                    navigate('/novedades');
                }
            } else {
                runNotification('Algo salio mal, vuelva a intentarlo');
                showLoading(false);
            }
        },
        onError: (err) => {
            runNotification('Algo salio mal, vuelva a intentarlo');
            showLoading(false);
        }
    });

    const runNotification = (msg: string, typeNot: TypeNotification = TypeNotification.warning) => {
        showNotification({
            show: true,
            message: msg,
            typeNotification: typeNot
        });
    }

    useEffect(() => {
        if (message === 'User is not confirmed.') {
            navigate('/login');
        }

        if (isAuthenticated) {
            // get data user
            showLoading(true);
            getUserConvenio();
        } else {
            showLoading(false);
        }

        const severity = status === StoreStatus.Failed ? 'danger' : status === StoreStatus.Succeeded ? 'success' : 'info'
        setSeverity(severity);
    }, [formData?.username, message, navigate, status, isAuthenticated]);

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                setFormData(values);
                actions.setSubmitting(false);
                // call auth/signIn
                showLoading(true);
                dispatch(signIn(values));
            }}
            initialValues={{
                username: '',
                password: ''
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, isValid, dirty }) => (
                <Form noValidate onSubmit={handleSubmit} className="login-form">
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Usuario"
                                    aria-describedby="inputGroupPrepend"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                />
                                {/* {errors.username && touched.username ? <div className="py-2 text-center text-danger form-text">{errors.username}</div> : null} */}
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationFormikPassword">
                            <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                            />
                            {/* {errors.password && touched.password ? <div className="py-2 text-center text-danger form-text">{errors.password}</div> : null} */}
                        </Form.Group>
                    </Row>
                    {
                        message?.length ? <Row className="mb-3 text-center">
                            <Form.Text id="passwordHelpBlock" className={`text-${severity}`}>
                                Datos incorrectos. Inténtalo nuevamente. 
                            </Form.Text>
                        </Row> : null
                    }
                    <Row className="text-center btn-submit mt-4">
                        <Col>
                            <Button type="submit" className="text-white" disabled={isSubmitting || (!isValid || !dirty)}>
                                <span className="text-uppercase">Ingresar</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default LoginForm;
