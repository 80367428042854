import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MessageNotification from "../common/MessageNotification";
import SideBarMenu from "../common/SideBarMenu";
import TopBar from "../common/TopBar";

const MainLayout = () => {
    // const elRef = React.createRef<HTMLInputElement>();
    // const [height, setHeight] = useState(0);

    // useEffect(() => {
    //     if (!elRef?.current?.clientHeight) {
    //       return;
    //     }
    //     setHeight(elRef?.current?.clientHeight);
    //   }, [ elRef?.current?.clientHeight ]);

    return (
        <div className="d-flex">
            <TopBar />
            <nav className="navbar flex-shrink-0 p-0"
                style={{ width: "300px", zIndex: "1" }}
                // ref={elRef}
            >
                <SideBarMenu />
            </nav>
            <div className="flex-grow-1 p-0"
                style={{ 
                    width: `calc(100% - 300px)`,
                    minHeight: '100vh'
                }}>
                    <div className="main-content px-2" style={{ marginTop: 88 }}>
                        <Outlet />
                    </div>
            </div>
            <MessageNotification />
        </div>
    )
}

export default MainLayout