import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type Props = {}

const LoadingSpinner = (props: Props) => {

    const { showLoading } = useSelector((state: RootState) => state.appState);
    
    return (
        <div style={{
            display: `${showLoading ? 'block': 'none'}`,
            position: 'fixed',
            zIndex: 1090,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        }}>
            <div className="text-center content-spinner">
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default LoadingSpinner