import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { jtdApi } from '../api/JTDApi';
import { ILiquidacionDetalles } from '../interfaces/liquidacionInterface';
import { RootState } from '../redux/store';

export const useLiquidacionDetalles = (liquidacionId: number) => {
    const { userconfig } = useSelector((state: RootState) => state.appState);

    const fetchLiquidacionDetalles = async () => {
        var convenio_Id;
        if (userconfig) {
            convenio_Id = userconfig[0].convenio_id
        } else {
            var res = JSON.parse(window.localStorage.getItem('userconfig')!);
            
            if (res !== undefined && res !== null) {
                convenio_Id = res[0].convenio_id;
            }
        }

        const { data } = await jtdApi.get<ILiquidacionDetalles>(`/api/liquidaciondetalles`, {
            params: {
                convenioid: convenio_Id,
                liqid: liquidacionId
            }
        });
        
        return data;
    }

    const { data, error, isError, isLoading } = useQuery("getLiquidacionDetalles", fetchLiquidacionDetalles);
      
    return {
        liquidacionDetalles: data,
        error,
        isError,
        isLoading
    }
}