import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { DataNovedad, NovedadDecision } from '../../interfaces/novedadesInterface';
import { useMutation, useQueryClient } from 'react-query';
import { jtdApi } from '../../api/JTDApi';
import { useAppDispatch } from '../../hooks/hooks';
import { setShowLoadingState } from '../../redux/features/AppState.slice';
import { TypeNotification } from '../../interfaces/messageNotificationInterface';
import MessageNotification from '../common/MessageNotification';
import UseNotification from '../../hooks/UseNotification';
import useMotivosList from '../../hooks/useMotivosList';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: DataNovedad
}

interface IPayload {
    motivo: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const RechazarModal = (props: Props) => {
    const { showNotification } = UseNotification();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const { motivoList } = useMotivosList({ modulo: 'novedades' });

    const schema = yup.object().shape({
        motivo: yup.string().required('El motivo es requerido')
    });

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onRejectNovedad = async (reason: IPayload) => {
        showLoading(true);
        
        if (props.data) {
            const { id, cedula, contrato } = props.data;

            const payload = {
                novedadid: id,
                decisions: [
                    {
                      "decision": NovedadDecision.Reject,
                      "installmentValue": 0,
                      "identification": cedula,
                      "loanId": +contrato
                    }
                ],
                motivo: reason.motivo
            }

            await jtdApi.post(`/api/recharzarnovedad`, payload);
        } else {
            showLoading(false)
        }
    }

    const {mutate : rejectNovedad} = useMutation(onRejectNovedad, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries('getNovedades');
            queryClient.invalidateQueries('getRechazados');
            props.onHide();
            showLoading(false);
        },
        onError: (err: any) => {
            runNotification(err.message ? err.message: "");
            showLoading(false);       
        }
    });

    const runNotification = (msg: string, typeNot: TypeNotification = TypeNotification.warning) => {
        showNotification({
            show: true,
            message: msg,
            typeNotification: typeNot
        });
    }    

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="reject-modal"
        >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>
                <div className="container">
                    <p className="text-center">¿Rechazas el descuento por nómina?</p>
                    <Formik
                        validationSchema={schema}
                        initialValues={{
                            motivo: ''
                        }}
                        onSubmit={async values => {
                            await sleep(300);
                            rejectNovedad(values);
                        }}
                    >
                        {({ isSubmitting, errors, touched, isValid, dirty }) => (
                            <Form>
                                <Field
                                    component="select"
                                    id="motivo"
                                    name="motivo"
                                    multiple={false}
                                    className="form-select"
                                >
                                    <option value=""></option>
                                    {
                                        motivoList ? motivoList.data.map((value, index) => {
                                            return (<option key={index} value={value.id}>{value.nombre}</option>)
                                        }) : null
                                    }
                                </Field>

                                {errors.motivo && touched.motivo ? <div className="py-2 text-center text-danger error-message-font">{errors.motivo}</div> : null}
                            
                                <Row className="text-center mx-auto btn-submit mt-3">
                                    <Col>
                                        <Button className="btn me-2 text-uppercase" type="submit" disabled={isSubmitting || (!isValid || !dirty)}>Si</Button>
                                        <Button className="btn text-uppercase" onClick={props.onHide}>Cancelar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RechazarModal