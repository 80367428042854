import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { DataNovedad, NovedadDecision } from '../../interfaces/novedadesInterface';
import { useMutation, useQueryClient } from 'react-query';
import { jtdApi } from '../../api/JTDApi';
import { useAppDispatch } from '../../hooks/hooks';
import { setShowLoadingState } from '../../redux/features/AppState.slice';
import { useState } from 'react';

type Props = {
    show: boolean;
    onHide: () => void,
    data?: DataNovedad,
    val?: number,
    format?: string
}

interface IPayload {
    cuotaautorizada: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const EditarModal = (props: Props) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const valMax: number = props.data ? +props.data.cuota: 1000000;
    const [ errorCuota, setErrorCuota ] = useState('');

    const schema = yup.object().shape({
        cuotaautorizada: yup.number()
        .typeError('Ingrese solo números')
        .required('Este campo es requerido')
        .positive('El valor debe ser positivo')
        .min(props.val!, 'El valor de la cuota debe ser igual o mayor a '+ props.format)
        .max(valMax - 1, 'El nuevo valor no puede ser igual o superior a la cuota Pre aprobada')
    });

    const showLoading = (value: boolean) => {
        dispatch(setShowLoadingState({ showloading: value }));
    }

    const onEditNovedad = async (fee: IPayload) => {
        showLoading(true);

        if (props.data) {
            const { id, cedula, contrato } = props.data;

            const payload = {
                novedadid: id,
                "decisions": [
                    {
                      "decision": NovedadDecision.Modify,
                      "installmentValue": +fee.cuotaautorizada,
                      "identification": cedula,
                      "loanId": +contrato
                    }
                ],
                cuotaautorizada: +fee.cuotaautorizada
            }

            await jtdApi.post(`/api/editarnovedad`, payload);
        } else {
            showLoading(false)
        }
    }

    const {mutate : editNovedad} = useMutation(onEditNovedad, {
        onSuccess: () => {
            queryClient.invalidateQueries('getNovedades');
            queryClient.invalidateQueries('getAprobados');
            setErrorCuota('');
            showLoading(false);
            props.onHide();
        },
        onError: (err: any) => {
            if (err.response.data && err.response.data.message === 'error_cuota') {
                setErrorCuota('El valor de la cuota debe ser igual o mayor a '+ props.format);
            }
            showLoading(false);       
        }
    });
    
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="reject-modal"
        >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>
                <div className="container mb-3">
                    <p className="text-center">Ingresa el valor de la cuota autorizada</p>
                    <Formik
                        validationSchema={schema}
                        initialValues={{
                            cuotaautorizada: ''
                        }}
                        initialTouched={{
                            cuotaautorizada: true,
                        }}
                        onSubmit={async (values) => {
                            await sleep(300);
                            setErrorCuota('');
                            editNovedad(values);
                        }}
                    >
                        {({ isSubmitting, errors, touched, isValid, dirty, values, setFieldValue, handleBlur }) => (
                            <Form>
                                <input
                                    id="cuota-1"
                                    className="form-control"
                                    placeholder="$"
                                    name="cuotaautorizada"
                                    value={values.cuotaautorizada}
                                    onChange={e => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        if (!value || regex.test(value.toString())) {
                                            setFieldValue("cuotaautorizada", value);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />

                                {errors.cuotaautorizada && touched.cuotaautorizada && <div className="py-2 text-center text-danger error-message-font">{errors.cuotaautorizada}</div>}
                            
                                {errorCuota && errorCuota !== '' && <div className="py-2 text-center text-danger error-message-font">{errorCuota}</div>}

                                <Row className="text-center mx-auto btn-submit mt-3">
                                    <Col>
                                        <Button className="btn me-2 text-uppercase" type="submit" disabled={isSubmitting || (!isValid || !dirty)}>Aprobar prestamo</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditarModal