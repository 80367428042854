import { useEffect, useState } from 'react';
import { ColDef, IRowNode } from 'ag-grid-community';

type Props = {
    colDef: ColDef;
    node: IRowNode;
    context: any
}

const BtnCellDetailsRender = (props: Props) => {
    const [ hasNovedad, setHasNovedad] = useState(false);

    useEffect(() => {
        setHasNovedad(props.node.data.tiene_novedad);
    }, [])
    

    const invokeParentAddNovelty = () => {
        props.context.addNovelty(props.node.data);
    }

    const invokeParentUndo = () => {
        props.context.undoNovelty(props.node.data);
    }

    return (
        <div className="btn-actions">
            <button type="button" className={`btn px-1 py-0 fs-5 novedad ${ (hasNovedad) ? "disabled": "" }`} onClick={() => !hasNovedad && invokeParentAddNovelty()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Novedad
                </p>
            </button>
            <button type="button" className={`btn px-1 py-0 fs-5 undo ${ (!hasNovedad) ? "disabled": "" }`} onClick={() => hasNovedad && invokeParentUndo()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Deshacer
                </p>
            </button>
        </div>
    )
}

export default BtnCellDetailsRender