import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import appStateSlice from './features/AppState.slice';
import authReducer from './features/Auth.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appState: appStateSlice,
  }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>