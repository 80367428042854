import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import './custom.scss'
import { QueryClient, QueryClientProvider } from 'react-query'
import MainLayout from './components/layout/MainLayout';
import { routes } from './routes';
import Error from './pages/Error';
import Login from './pages/Login';
import { useAppDispatch } from './hooks/hooks';
import { refreshToken } from './redux/features/Auth.services';
import LoadingSpinner from './components/common/LoadingSpinner';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true
    },
  },
});

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch])

  return (
    <QueryClientProvider client={queryClient}>
      <LoadingSpinner />
      <BrowserRouter>
        <Routes>
          <Route index element={ <Login /> } />
          <Route path="/" element={ <MainLayout /> }>
            {routes}
          </Route>
          {/* <Route path='*' element={<Error />} /> */}
          <Route path="*" element={<Navigate to={'/'} replace />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
