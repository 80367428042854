import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { AgGridReact } from "ag-grid-react";
import { ColDef } from 'ag-grid-community';
import BtnCellRenderer from '../components/BtnCellRenderer';
import BtnDetailsCellRenderer from '../components/BtnDetailsCellRenderer';
import DetallesModal from '../components/widgets/DetallesModal';
import AprobarModal from '../components/widgets/AprobarModal';
import RechazarModal from '../components/widgets/RechazarModal';
import EditarModal from '../components/widgets/EditarModal';
import { DataNovedad } from '../interfaces/novedadesInterface';
import { useNovedades } from '../hooks/useNovedades';
import AprobarTodasSolicitudModal from './widgets/AprobarSolicitudes';
import { TypeNotification } from '../interfaces/messageNotificationInterface';
import UseNotification from '../hooks/UseNotification';
import { getNumberWithDecimal } from '../utils/helper';

type Props = {}

const TabPendientes = (props: Props) => {
    const gridRef = useRef<AgGridReact>(null);
    const [ modalDetailsShow, setModalDetailsShow ] = useState(false);
    const [ modalApproveShow, setModalApproveShow ] = useState(false);
    const [ modalRejectShow, setModalARejectShow ] = useState(false);
    const [ modalApproveAllShow, setModalApproveAllShow ] = useState(false);
    const [ modalEditShow, setModalEditShow ] = useState(false);
    const [ novedad, setNovedad ] = useState<DataNovedad>();
    const [ rowData, setRowData ] = useState<DataNovedad[]>([]);
    const { novedades } = useNovedades();
    const [ currentNovedades, setCurrentNovedades ] = useState<DataNovedad[]>([]);
    const [ minInstallment, setMinInstallment ] = useState<number>(0);
    const [ minInstallmentFormated, setMinInstallmentFormated ] = useState<string>();

    const { showNotification } = UseNotification();

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: false,
            enableRowGroup: false,
            enablePivot: false,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const formatValueCurrency = (params: any) => {
        if (params.value !== undefined && params.value !== '' && params.value !== null) {
          return getNumberWithDecimal(params.value);
        } else {
          return '-'
        }
    }

    const [columnDefs] = useState([
        {
            headerName: "Nombre",
            field: "nombrecompleto",
            minWidth: 225,
            cellClass: "details-cell",
        },
        {
            headerName: "Cédula",
            field: "cedula",
            minWidth: 130,
            cellClass: "details-cell",
            // flex: 1
        },
        {
            headerName: "NIT",
            field: "nit",
            minWidth: 145,
            cellClass: "details-cell",
        },
        {
            headerName: "Monto preaprobado",
            field: "montopreaprobado",
            minWidth: 180,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Cuota",
            field: "cuota",
            minWidth: 150,
            valueFormatter: (params: any) => formatValueCurrency(params),
            cellClass: "details-cell",
        },
        {
            headerName: "Plazo",
            field: "plazo",
            minWidth: 120,
            cellClass: "details-cell",
        },
        {
            headerName: "Fecha solicitud",
            field: "fechasolicitud",
            minWidth: 150,
            cellClass: "details-cell",
        },
        {
            headerName: "",
            field: "id",
            cellRenderer: BtnDetailsCellRenderer,
            minWidth: 70,
            cellClass: "details-cell",
            sortable: false,
            resizable: false,
            filter: false
        },
        {
            headerName: "Acciones",
            field: 'id',
            cellRenderer: BtnCellRenderer,
            minWidth: 180,
            cellClass: "actions-cell",
            sortable: false,
            resizable: false,
            filter: false
        }
    ]);    

    const onShowApproveAll = async () => {
        let allNovIds: DataNovedad[] = [];
        if (gridRef.current) {

            gridRef.current.api.forEachNode((node) => {
                allNovIds.push(node.data);
            });

            if (allNovIds.length > 0) {
                setCurrentNovedades(allNovIds);
                setModalApproveAllShow(true);
            }
        }
    }

    const onGridReady = useCallback((params: any) => {
        if (novedades && novedades.data.length > 0) {
            setRowData(novedades.data);
        }
    }, []);

    const runNotification = () => {
        showNotification({
            show: true,
            message: 'Mostrar alerta en vivo',
            typeNotification: TypeNotification.warning
        });
    }

    const showDetails = (novObje: any) => {
        setNovedad(novObje);
        setModalDetailsShow(true);
    }

    const showApprove = (novObje: any) => {
        setNovedad(novObje);
        setModalApproveShow(true);
    }

    const showReject = (novObje: any) => {
        setNovedad(novObje);
        setModalARejectShow(true);
    }

    const showEdit = (novObje: any) => {
        setNovedad(novObje);
        setModalEditShow(true);
    }

    useEffect(() => {
        if (novedades && novedades.data.length > 0) {
            setRowData(novedades.data);
            setMinInstallment(novedades.cuotaminima);
            const value = getNumberWithDecimal(novedades.cuotaminima.toString(), 0);
            setMinInstallmentFormated(value);
        } else {
            setRowData([]);
        }
    }, [ novedades ]);

    return (
        <>
            {/* <button type="button" className="btn btn-primary" onClick={() => runNotification() }>Mostrar alerta en vivo</button> */}
            <Card body className="rounded-0 border-0" style={{ height: "calc(95vh - 300px)" }}>
                <Stack className="h-100 ag-theme-material">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        rowHeight={50}
                        pagination={true}
                        onGridReady={onGridReady}
                        context={{
                            showDetails,
                            showApprove,
                            showReject,
                            showEdit
                        }}
                    ></AgGridReact>
                </Stack>
            </Card>
            <div className="btns-approve-all w-100">
                <div className="container mt-4">
                    <div className="d-grid gap-2 d-flex justify-content-end">
                        <button className="btn btn-secondary me-md-2 text-uppercase" type="button" onClick={() => onShowApproveAll()}>Aprobar todos</button>
                    </div>
                </div>
            </div>
            <DetallesModal show={modalDetailsShow} onHide={() => setModalDetailsShow(false)} data={novedad} />
            <AprobarModal show={modalApproveShow} onHide={() => setModalApproveShow(false)} data={novedad} />
            <RechazarModal show={modalRejectShow} onHide={() => setModalARejectShow(false)} data={novedad} />
            <EditarModal show={modalEditShow} onHide={() => setModalEditShow(false)} data={novedad} val={minInstallment} format={minInstallmentFormated} />
            <AprobarTodasSolicitudModal show={modalApproveAllShow} onHide={() => setModalApproveAllShow(false)} data={currentNovedades} />
        </>
    )
}

export default TabPendientes