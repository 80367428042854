import moment from 'moment';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const TopBar = () => {
  moment.locale('es');
  const dateNow = moment();
  const { pageName } = useSelector((state: RootState) => state.appState);  
  
  return (
    <nav className="navbar position-fixed header-fixed"
     style={{ 
        width: `calc(100% - 300px)`,
        marginLeft: '300px',
        boxShadow: "unset",
        backgroundColor: "#fff",
        color: "#000"
      }}>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-12">
            <h5 className="font-face-ws-sBold text-uppercase mb-0"
              style={{ color: "rgba(101, 101, 101, 1)", paddingLeft: '0.5rem' }}
            >{ dateNow.format("DD MMMM YYYY") }</h5>
          </div>
          <div className="w-100"></div>
          <div className="col-12">
            <h1 className="font-face-ws-bold mb-0 title">{ pageName }</h1>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopBar