import { Toast } from 'react-bootstrap';
import ToastContainer from 'react-bootstrap/ToastContainer';
import UseNotification from '../../hooks/UseNotification';

const MessageNotification = () => {
    const { notification, onClose } = UseNotification();
    
    return (
        <div>
            {/* text-white bg-opacity-75 ${props.typeNotification} */}
            <ToastContainer
                className="p-3"
                position={'top-end'}
                style={{ zIndex: 1 }}
            >

                <Toast
                    onClose={onClose}
                    show={notification!.show}
                    autohide
                    delay={2200}
                    className={`position-fixed align-items-center message-notification top-0 end-0 py-1 px-3 mt-2 text-black bg-opacity-50 ${notification?.typeNotification}`}
                    style={{zIndex: "11"}}
                    role="alert"
                >
                    <Toast.Header className="w-100 d-flex justify-content-end"></Toast.Header>
                    <Toast.Body>{notification?.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default MessageNotification