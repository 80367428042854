import { Auth } from '@aws-amplify/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CognitoUserAmplify } from '../../interfaces/cognitoInterface';
import { SignInParameters } from '../../interfaces/cognitoInterface';

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
    const cognitoUser: CognitoUserAmplify = await Auth.currentAuthenticatedUser();
    
    return cognitoUser?.attributes;
})

export const signIn = createAsyncThunk('auth/signIn', async ({ username, password }: SignInParameters) => {
    const cognitoUser: CognitoUserAmplify = await Auth.signIn(username, password);

    return cognitoUser.attributes;
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
    try {
        return Auth.signOut();
    } catch (error) {
        // error signing out
    }
})