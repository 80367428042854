import { ReactNode } from "react";
import { Route } from "react-router-dom";
import PageWrapper from "../components/layout/PageWrapper";
import { RouteType } from "../interfaces/routeInterface";
import appRoutes from "./appRoutes";

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) => (
    route.index ? (
      <Route
        index
        path={route.path}
        element={
          <PageWrapper state={route.state} pageName={route.sidebarProps ? route.sidebarProps.displayText: ""} showLoading={false}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state} pageName={route.sidebarProps? route.sidebarProps.displayText : "" } showLoading={false}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && (
          generateRoute(route.child)
        )}
      </Route>
    )
  ));
};

export const routes: ReactNode = generateRoute(appRoutes);