import { ColDef, IRowNode } from 'ag-grid-community';
import { useEffect, useState } from 'react';

type Props = {
    colDef: ColDef;
    node: IRowNode;
    valuesCount: number;
    context: any,
}

const BtnCellLiquidationRender = (props: Props) => {
    const { node } = props;
    const [ selected, setSelected ] = useState(false);

    useEffect(() => {
        const selectListener = (event: any) => {
            setSelected(node.isSelected()!);
        };        
    
        node.addEventListener('rowSelected', selectListener);
    
        return () => {
          node.removeEventListener('rowSelected', selectListener);
        };
    }, []);    

    const invokeParentDetalles = () => {
        props.context.goToDetalles(props.node.data.id);
    }

    const invokeParentPayment = () => {
        props.context.showPayment(props.node.data);
    }

    const invokeParentDownload = () => {
        props.context.downloadExcelFile(props.node.data.id);
    }    

    return (
        <div className="btn-actions">
            <button type="button" className={`btn px-1 py-0 fs-5 details ${ (selected || node.data.isblocked) ? "disabled" : "" }`} onClick={() => !selected && invokeParentDetalles()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Detalles
                </p>
            </button>
            <button type="button" className={`btn px-1 py-0 fs-5 download ${ (selected || node.data.isblocked) ? "disabled" : "" }`} onClick={() => !selected && invokeParentDownload()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Descargar
                </p>
            </button>
            <button type="button" className={`btn px-1 py-0 pay ${ (selected || node.data.isblocked) ? "disabled" : "" }`} onClick={() => !selected && invokeParentPayment()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Pagar
                </p>
            </button>
        </div>
    )
}

export default BtnCellLiquidationRender