import { ColDef, IRowNode } from "ag-grid-community";
import './styles.scss'

type Props = {
  colDef: ColDef;
  node: IRowNode;
  context: any
}

const BtnDetailsCellRenderer = (props: Props) => {

  const invokeParentDetalles = () => {
    props.context.showDetails(props.node.data);
  };

  return (
    <div className="btn-details">
      <button type="button" className="btn px-1 py-0" onClick={invokeParentDetalles}>
        <i className="bi"></i>
        <br />
        <p style={{ fontSize: 10, marginTop: '-0.5rem' }}>
          Detalles
        </p>
      </button>
    </div>
  )
}

export default BtnDetailsCellRenderer