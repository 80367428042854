import { RouteType } from "../interfaces/routeInterface";
import DetallesLiquidacion from "../pages/DetallesLiquidacion";
import HistorialPagos from "../pages/HistorialPagos";
import Liquidacion from "../pages/Liquidacion";
import Novedades from "../pages/Novedades";

const appRoutes: RouteType[] = [
    {
        index: true,
        element: <Novedades />,
        state: "home"
    },
    {
        path: "/novedades",
        element: <Novedades />,
        state: "novedades",
        sidebarProps: {
            displayText: "Novedades"
        },
        showInSideBar: true
    },
    {
        path: "/liquidacion",
        element: <Liquidacion />,
        state: "liquidacion",
        sidebarProps: {
            displayText: "Liquidación"
        },
        showInSideBar: true,
    },
    {
        path: "/liquidacion/detalles/:liquidacionId",
        element: <DetallesLiquidacion />,
        state: "detalles",
        parentState: "liquidacion",
        sidebarProps: {
            displayText: "Detalles"
        },
        showInSideBar: false
    },
    {
        path: "/historialpagos",
        element: <HistorialPagos />,
        state: "historialpagos",
        sidebarProps: {
            displayText: "Historial de pagos"
        },
        showInSideBar: true
    }
];

export default appRoutes;