import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserConfig } from '../../interfaces/userInterface';

type appState = {
    pageState: string,
    pageName: string
    showLoading: boolean;
    userconfig: UserConfig[] | null;
}

const initialState: appState = {
    pageState: "",
    pageName: "",
    showLoading: false,
    userconfig: null
}

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setAppState: (state, action: PayloadAction<{ pagestate: string, pagename: string, showloading: boolean }>) => {
            state.pageState = action.payload.pagestate
            state.pageName = action.payload.pagename
            state.showLoading = action.payload.showloading
        },
        setShowLoadingState: (state, action: PayloadAction<{ showloading: boolean }>) => {
            state.showLoading = action.payload.showloading
        },
        setUserConfig: (state, action: PayloadAction<{ user: UserConfig[] }>) => {
            state.userconfig = action.payload.user
        }
    }
})

export const {
    setAppState,
    setShowLoadingState,
    setUserConfig
} = appStateSlice.actions;

export default appStateSlice.reducer;