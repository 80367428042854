import { ColDef, IRowNode } from 'ag-grid-community';

type Props = {
    colDef: ColDef;
    node: IRowNode;
    context: any
}

const BtnCellDescargarRender = (props: Props) => {    
    const invokeParentDownload = () => {
        props.context.downloadExcelFile(props.node.data.id);
    };

    return (
        <div className="btn-actions">
            <button type="button" className="btn px-1 py-0 fs-5 download" onClick={() => invokeParentDownload()}>
                <i className="bi" aria-hidden="true"></i>
                <br />
                <p style={{ fontSize: 10, marginTop: '-0.3rem' }}>
                    Descargar
                </p>
            </button>
        </div>
    )
}

export default BtnCellDescargarRender