import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RouteType } from '../../interfaces/routeInterface';
import { RootState } from '../../redux/store';
import './styles.scss';

type Props = {
    item: RouteType
}

const SideBarItem = ({ item }: Props) => {
    const navigate = useNavigate();
    const { pageState } = useSelector((state: RootState) => state.appState);

    return (
        <li className={`nav-item nav-item-left-side fs-4" ${pageState === item.state ? "nav-item-selected": ""}`}>
            <button type="button"
                className="btn btn-link nav-link text-start w-100 fs-6 text-uppercase"
                onClick={() => {
                    navigate(`${item.path}`)
                }}
            >
                {item.sidebarProps?.displayText}
            </button>
        </li>
    )
}

export default SideBarItem